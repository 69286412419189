import {
  IGenerateOTPPayload,
} from "../../../store/auth/types";
import { getFacilityKey } from "../../../utils/token";
import {
  apiPrivateAxios,
  apiPrivateAxiosUseOldAccessToken,
  apiPublicAxios,
} from "../../axios";
import {
  ITokenData,
  ICreateNewPassword,
  IForgotPasswordData,
  IAlterPassword,
  ILogInData,
  IUpdateData,
  IOTPData,
} from "./types";

export const authRequest = {
  logIn: ({
    userName,
    password,
    facilityKey,
  }: ILogInData): Promise<ITokenData> => {
    return apiPublicAxios.post(
      `/token?facilityKey=${facilityKey}`,
      {},
      {
        auth: {
          username: userName,
          password,
        },
      }
    );
  },
  generateOtp: ({
    method,
    authorityOrigin,
    accessToken,
  }: IGenerateOTPPayload): Promise<ITokenData> => {
    return apiPublicAxios.post(
      `token/generateOTPClient?facilityKey=${getFacilityKey()}`,
      {
        method,
        authorityOrigin,
        accessToken,
      }
    );
  },
  updateToken: ({
    accessToken,
    refreshToken,
    grantType,
  }: IUpdateData): Promise<ITokenData> =>
    apiPrivateAxios.put("/token", {
      access_token: accessToken,
      refresh_token: refreshToken,
      grant_type: grantType,
    }),
  logOut: (): Promise<void> => {
    return apiPrivateAxiosUseOldAccessToken.delete("/token", {});
  },
  forceLogOut: (facilityKey: string): Promise<void> => {
    return apiPrivateAxiosUseOldAccessToken.delete(`/token?facilityKey=${facilityKey}`, {});
  },

  verifyOTPToken: ({
    otp,
    accessToken,
    isRememberMe,
    authorityOrigin,
  }: IOTPData): Promise<ITokenData> =>
    apiPrivateAxios.post(
      `/token/verifyOTPToken?facilityKey=${getFacilityKey()}`,
      {
        otp,
        isRememberMe,
        accessToken,
        authorityOrigin,
      }
    ),
  forgotPassword: ({
    email,
    changeType,
    facilityKey,
  }: IForgotPasswordData): Promise<void> =>
    apiPublicAxios.post(`/user/forgot-password?facilityKey=${facilityKey}`, {
      email,
      changeType,
    }),
  createNewPassword: ({
    passwordResetToken,
    password,
    facilityKey,
  }: ICreateNewPassword): Promise<void> =>
    apiPublicAxios.post(`/user/reset-password?facilityKey=${facilityKey}`, {
      passwordResetToken,
      password,
    }),
  alterPassword: ({
    email,
    oldPassword,
    password,
    facilityKey,
  }: IAlterPassword): Promise<void> =>
    apiPublicAxios.post(`/user/alter-password?facilityKey=${facilityKey}`, {
      email,
      oldPassword,
      password,
    }),

};

export default { authRequest };
