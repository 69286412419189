import { toast } from "react-toastify";
import { extractErrorMessage } from "./error";

export const showSuccessNotify = (message: string, id?: string): void => {
  toast.success(message, {
    theme: "light",
    toastId: id
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const showErrorNotify = (error: any, id?: string): void => {
  const message = extractErrorMessage(error);

  toast.error(message, {
    theme: "light",
    toastId: id
  });
};

export const showReminderNotify = (message: string): void => {
  toast.success(message, {
    theme: "light",
    autoClose: 8000
  });
};