import parseJSON from "../../../utils/parseJSON";
import {
  AUTHORIZATION_TOKEN,
  LAST_ACTIVITY,
  VERIFIED_EMAIL,
} from "./constants";

interface IToken {
  accessToken: string;
  refreshToken: string;
  timeCreateToken?: number;
  expireIn?: number;
  refreshTokenExpireIn?: number;
}

export const deleteLocalData = (): void => {
  localStorage.removeItem(AUTHORIZATION_TOKEN);
  localStorage.removeItem(VERIFIED_EMAIL);
  localStorage.removeItem(LAST_ACTIVITY);
};

export const getLocalTokenData = (): IToken | null => {
  const retriveObject = localStorage.getItem(AUTHORIZATION_TOKEN);
  if (retriveObject) {
    return parseJSON(retriveObject) as IToken;
  }
  return null;
};

export const setLocalData = (token: IToken): void => {
  localStorage.setItem(AUTHORIZATION_TOKEN, JSON.stringify(token));
};

export const setUserActivity = (activity: number): void => {
  localStorage.setItem(LAST_ACTIVITY, activity.toString());
};

export const setVerifiedStateListing = (state: string): void => {
  localStorage.setItem(VERIFIED_EMAIL, state);
};

export const getUserActivity = (): number | null => {
  const retriveObject = localStorage.getItem(LAST_ACTIVITY);
  if (retriveObject) {
    return retriveObject as unknown as number;
  }
  return null;
};

export const getVerificationState = (): string | null => {
  const state = localStorage.getItem(VERIFIED_EMAIL);
  if (state) {
    return state;
  }
  return null;
};
