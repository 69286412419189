import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api/index";
import { IGetParams } from "../../api/types";
import { IError } from "../types/common";
import { IServiceItems } from "../types/state";
import { IServiceSelector } from "./types";

export const getService = createAsyncThunk<
  IServiceItems,
  { id: string },
  { rejectValue: IError }
>("servicesSlice/getService", async ({ id }, { rejectWithValue }) => {
  try {
    return await API.getService(id);
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const getServiceSelectors = createAsyncThunk<
  IServiceSelector[],
  IGetParams | undefined,
  { rejectValue: IError }
>(
  "serviceSlice/getServiceSelectors",
  async (params = {}, { rejectWithValue }) => {
    try {
      return await API.getServiceSelectors(params);
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);
