import { ReactNode, FC } from "react";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import useStyles from "./styles";

interface IProps extends ButtonProps {
  children: ReactNode;
  isLoading?: boolean;
}

const Button: FC<IProps> = ({
  children,
  isLoading,
  variant = "contained",
  ...props
}) => {
  const classes = useStyles();

  return (
    <MuiButton {...props} variant={variant}>
      {children}
      {isLoading && (
        <span className={classes.loaderWrapper}>
          <CircularProgress size={20} className={classes.loader} />
        </span>
      )}
    </MuiButton>
  );
};

export default Button;
