import qs from "qs";
import { IDesignationSelector } from "../../../store/stateDesignation/types";
import { apiPrivateAxios } from "../../axios";
import { IGetParams } from "../../types";

// TODO: think to combine with statesRequest
export const stateDesignationRequest = {
  getStateDesignationSelectors: (
    params?: IGetParams
  ): Promise<IDesignationSelector[]> =>
    apiPrivateAxios.get("/state-designation/selector", {
      params,
      paramsSerializer: (paramsData) =>
        // TODO: replace to the custom method (not use qs)
        qs.stringify(paramsData, { arrayFormat: "repeat" }),
    }),
};

export default { stateDesignationRequest };
