/* eslint-disable @typescript-eslint/no-explicit-any */
import jwt_decode from "jwt-decode";
import { getLocalTokenData } from "../api/services/localData/tokenActions";
import { UserRole } from "../constants/user";
import {
  ACCESS_TOKEN_PAYLOAD_NAME_KEY,
  ACCESS_TOKEN_PAYLOAD_NAME_IDENTIFIER_KEY,
  ACCESS_TOKEN_PAYLOAD_ROLE_KEY,
  FacilityKey,
} from "../constants/token";

interface IJwtDecode {
  [key: string]: any;
}

export const jwtDecode = (jwt: string): IJwtDecode => {
  try {
    return jwt_decode(jwt);
  } catch (e) {
    return {};
  }
};

export const getDecodedJwt = (): IJwtDecode => {
  const accessToken = getLocalTokenData()?.accessToken || "";
  return jwtDecode(accessToken);
};

// TODO: think, maybe move a user role to the redux store instead of extracting
// it each time from a localStorage
export const getUserRole = (): UserRole | undefined => {
  const decodedJwt = getDecodedJwt();
  return decodedJwt[ACCESS_TOKEN_PAYLOAD_ROLE_KEY];
};

export const getUserId = (): string | undefined => {
  const decodedJwt = getDecodedJwt();
  return decodedJwt[ACCESS_TOKEN_PAYLOAD_NAME_IDENTIFIER_KEY];
};

export const getFacilityKey = (): string => {
  const decodedJwt = getDecodedJwt();
  return decodedJwt[FacilityKey];
};

export const getUserEmail = (): string => {
  const decodedJwt = getDecodedJwt();
  return decodedJwt[ACCESS_TOKEN_PAYLOAD_NAME_KEY];
};
