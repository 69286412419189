import { createSlice } from "@reduxjs/toolkit";
import { IPatientState } from "./types";
import { getPatientReports } from "./thunks";

const initialState: IPatientState = {
  reports: null,
  error: null,
  status: "idle",
};

export const patientReportsSlice = createSlice<IPatientState, {}>({
  name: "patientReportsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPatientReports.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getPatientReports.fulfilled, (state, { payload }) => {
      state.status = "";
      state.error = null;
      state.reports = payload;
    });
    builder.addCase(getPatientReports.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.status = "idle";
    });
  },
});

export default patientReportsSlice.reducer;
