import { Theme } from "@mui/material/styles/createTheme";
import { Components } from "@mui/material/styles/components";

const getOverrides = (theme: Theme): Components<{}> => ({
  MuiInputBase: {
    styleOverrides: {
      root: {
        borderRadius: 4,
        "&:hover": {
          "&& fieldset": {
            borderColor: theme.palette.primary.dark,
          },
        },
        "&.Mui-disabled:hover": {
          "&& fieldset": {
            borderColor: theme.palette.secondary.main,
          },
        },
      },
      input: {
        fontSize: "14px",
        height: 32,
        fontFamily: "Poppins",
        boxSizing: "border-box",
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      input: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 4,
        color: theme.palette.secondary.dark,
        padding: theme.spacing(0.5, 1.25),
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        height: 32,
        padding: theme.spacing(0.5, 1.25),
        border: `1px solid ${theme.palette.primary.dark}`,
        "&: focus-visible": {
          borderColor: theme.palette.primary.dark,
        },
        "&.Mui-disabled": {
          borderColor: theme.palette.secondary.main,
        },
      },
      icon: {
        width: 20,
        color: theme.palette.primary.dark,
        "&+fieldset": {
          border: `1px solid ${theme.palette.primary.dark}`,
        },
        "&.Mui-disabled": {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        height: 32,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      contained: {
        textTransform: "none",
        boxShadow: "none",
        ":hover": {
          boxShadow:
            "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        },
      },
      containedPrimary: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        "&[disabled]": {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.common.white,
        },
      },
      containedSecondary: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.light,
        ":hover": {
          backgroundColor: theme.palette.primary.light,
        },
      },
      containedInfo: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.info.main,
        ":hover": {
          backgroundColor: theme.palette.info.main,
        },
      },
      outlined: {
        textTransform: "none",
        backgroundColor: theme.palette.common.white,
      },
      outlinedPrimary: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        ":hover": {
          backgroundColor: theme.palette.primary.light,
        },
      },
      outlinedSecondary: {
        color: theme.palette.text.primary,
        borderColor: "#C3D1DB",
        ":hover": {
          backgroundColor: theme.palette.primary.light,
          borderColor: theme.palette.primary.main,
        },
      },
      outlinedInfo: {
        color: theme.palette.text.primary,
        borderColor: theme.palette.info.main,
        ":hover": {
          backgroundColor: theme.palette.info.light,
          borderColor: theme.palette.info.main,
        },
      },
      text: {
        textTransform: "none",
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: theme.palette.primary.main,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontFamily: "Poppins",
        fontWeight: 500,
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        "& svg": {
          fontSize: 15,
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        background: theme.palette.common.white,
        padding: 0,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
        fontWeight: 400,
        fontSize: 14,
      },
    },
  },
});

export default getOverrides;
