/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseHeaders,
} from "axios";
import set from "lodash/set";

import {
  getLocalTokenData,
  deleteLocalData,
} from "./services/localData/tokenActions";
import { isValidToken, setTokenData } from "../helpers/token";

import { AUTH_PATHS } from "../pages/auth/routes/constants";
import { ITokenData } from "./services/authRequest/types";

const API_URL = process.env.REACT_APP_API_URL;

interface IAxiosInstanceProps {
  baseURL?: string;
  isPrivate?: boolean;
  withHeadersData?: boolean;
  useOldAccessToken?: boolean;
}

const DEFAULT_HEADERS = {
  "Content-Type": "application/json; charset=utf-8",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-auth-Token",
  timeoutErrorMessage: "timeout",
  timeout: 2000,
};
let isExpRefreshing: boolean = false;
let expQueue: any[] = [];

const requestParser = <T>(result: AxiosResponse<T>): T => result.data;
const requestWithHeaders = <T>(
  result: AxiosResponse<T>
): { data: T; headers: AxiosResponseHeaders } => ({
  data: result.data,
  headers: result.headers,
});
const errorHandler = <T>(error: AxiosError<T>): Promise<never> =>
  Promise.reject(error?.response);

const processExpQueue = (token: string | null): void => {
  expQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject();
    }
  });

  expQueue = [];
};

// Returns newAccessToken
const updateTokens = async (): Promise<string> => {
  const tokenData = getLocalTokenData();
  const accessToken = tokenData?.accessToken || "";
  const refreshToken = tokenData?.refreshToken || "";

  if (!accessToken || !refreshToken) {
    throw new Error("No accessToken or refreshToken");
  }

  try {
    const newTokenData = await axios
      .put<ITokenData>(`${API_URL}/token`, {
        access_token: accessToken,
        refresh_token: refreshToken,
        grant_type: "refresh_token",
      })
      .then((res) => res.data);
    setTokenData(newTokenData);
    return newTokenData?.access_token || "";
  } catch (e: any) {
    throw new Error(e);
  }
};

const onRefreshFail = (): void => {
  deleteLocalData();
  window.location.href = `${AUTH_PATHS.AUTH}${AUTH_PATHS.LOGIN}`;
};

const requestHandler = async (
  config: AxiosRequestConfig<any>,
  useOldAccessToken = false
): Promise<any> => {
  const tokenData = getLocalTokenData();
  const accessToken = tokenData?.accessToken || "";

  if (accessToken && useOldAccessToken) {
    set(config, "headers.Authorization", `Bearer ${accessToken}`);
    return config;
  }

  const isTokenValid = isValidToken();

  if (isTokenValid) {
    set(config, "headers.Authorization", `Bearer ${accessToken}`);
    return config;
  }

  if (isExpRefreshing) {
    return new Promise((resolve, reject) => {
      expQueue.push({ resolve, reject });
    })
      .then((token) => {
        set(config, "headers.Authorization", `Bearer ${token}`);
        return config;
      })
      .catch(() => {
        return config;
      });
  }

  isExpRefreshing = true;

  try {
    const newAccessToken = await updateTokens();
    processExpQueue(newAccessToken);
    set(config, "headers.Authorization", `Bearer ${newAccessToken}`);
    return config;
  } catch (e) {
    onRefreshFail();
    processExpQueue(null);
    return config;
  } finally {
    isExpRefreshing = false;
  }
};

const axiosInstance = (props?: IAxiosInstanceProps): AxiosInstance => {
  const baseURL = props?.baseURL || API_URL;
  const isPrivate = props?.isPrivate || false;
  const withHeadersData = props?.withHeadersData || false;
  const useOldAccessToken = props?.useOldAccessToken || false;

  const instance = axios.create({ baseURL, headers: DEFAULT_HEADERS });

  if (isPrivate) {
    instance.interceptors.request.use((config) =>
      requestHandler(config, useOldAccessToken)
    );
  }

  instance.interceptors.request.use((request) => request, errorHandler);

  instance.interceptors.response.use(
    withHeadersData ? requestWithHeaders : requestParser,
    (error) =>
      // Return only needed props: "status", "data"
      // eslint-disable-next-line prefer-promise-reject-errors
    {
      if(error.response?.status === 401) {
        deleteLocalData();
        window.location.href = `${AUTH_PATHS.AUTH}${AUTH_PATHS.LOGIN}`;
      } else {
      return Promise.reject({
        status: error.response?.status,
        data: error.response?.data,
      })
    }
  }
  );

  return instance;
};

export const apiPrivateAxios = axiosInstance({ isPrivate: true });

export const apiPrivateAxiosWithBaseURL = axiosInstance({
  isPrivate: true,
  baseURL: "",
});

export const apiPrivateAxiosWithHeaders = axiosInstance({
  isPrivate: true,
  withHeadersData: true,
});

// Use old "accessToken" for Authorization (e.g. for DELETE token method )
export const apiPrivateAxiosUseOldAccessToken = axiosInstance({
  isPrivate: true,
  useOldAccessToken: true,
});

export const apiPublicAxios = axiosInstance();

export default { apiPublicAxios, apiPrivateAxios, apiPrivateAxiosWithBaseURL };
