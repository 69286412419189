import { createSlice } from "@reduxjs/toolkit";
import {
  createNewAppointment,
  deleteAppointment,
  getAppointment,
  setAppointmentModal,
  updateAppointment,
} from "./thunk";
import { IAppointments } from "./types";

const initialState: IAppointments = {
  appointments: [],
  isOpenModal: false,
  error: null,
  responseStatus: "idle",
};

export const appointmentSlice = createSlice({
  name: "appointmentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAppointment.pending, (state) => {
      state.responseStatus = "loading";
      state.error = null;
    });
    builder.addCase(getAppointment.fulfilled, (state, { payload }) => {
      state.error = null;
      state.appointments = payload;
    });
    builder.addCase(setAppointmentModal.fulfilled, (state, { payload }) => {
      state.isOpenModal = payload;
    });
    builder.addCase(createNewAppointment.fulfilled, (state, { payload }) => {
      state.appointments = [...state.appointments, payload];
    });
    builder.addCase(updateAppointment.fulfilled, (state, { payload }) => {
      state.appointments = state.appointments.map((appointment) =>
        appointment.id === payload.id ? payload : appointment
      );
    });
    builder.addCase(deleteAppointment.fulfilled, (state, { payload }) => {
      state.appointments = state.appointments.filter(
        (appointment) => appointment.id !== payload
      );
    });
    builder.addCase(getAppointment.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.responseStatus = "idle";
    });
    builder.addCase(createNewAppointment.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.responseStatus = "idle";
    });
  },
});

export default appointmentSlice.reducer;
