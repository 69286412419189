import { FC, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { CircularProgress } from "@mui/material";
import { APP_PATHS } from "../pages/app/routes/constants";
import { PATHS } from "./constants";
import { AUTH_PATHS } from "../pages/auth/routes/constants";
import TwoFactorGuard from "../pages/auth/TwoFactorGuard/TwoFactorGuard";

const AuthLazy = lazy(
  () => import(/* webpackChunkName: "auth" */ "../pages/auth")
);

const AppLazy = lazy(
  () => import(/* webpackChunkName: "app" */ "../pages/app")
);

const MobileLazy = lazy(
  () => import(/* webpackChunkName: "mobile" */ "../pages/mobile")
);

const MainNavigation: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={PATHS.HOME_PAGE}
          element={<Navigate to={`${APP_PATHS.APP}${APP_PATHS.DASHBOARD}`} />}
        />
        <Route
          path={`${AUTH_PATHS.AUTH}/${AUTH_PATHS.TWO_FACTOR}`}
          element={<TwoFactorGuard />}
        />
        <Route
          path="coord/*"
          element={
            <Suspense fallback={<CircularProgress />}>
              <MobileLazy />
            </Suspense>
          }
        />
        <Route
          path="auth/*"
          element={
            <Suspense fallback={<CircularProgress />}>
              <AuthLazy />
            </Suspense>
          }
        />
        <Route
          path="app/*"
          element={
            <Suspense fallback={<CircularProgress />}>
              <AppLazy />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default MainNavigation;
