import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IState } from "./types";

const initialState: IState = {
  position: null,
};

export const buttonSlice = createSlice({
  name: "patientsSlice",
  initialState,
  reducers: {
    activeButton: (
      state,
      action: PayloadAction<{ position: number | null }>
    ) => {
      state.position = action.payload?.position;
    },
  },
});

export default buttonSlice.reducer;
