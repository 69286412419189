import { getFacilityKey } from "../../../utils/token";
import { apiPrivateAxiosWithBaseURL } from "../../axios";
import { IAdminFacilityData } from "./types";

const API_URL_ADMIN = process.env.REACT_APP_API_URL_ADMIN;

export const adminFacilityRequest = {
  getFacilityAdminDetails: (): Promise<IAdminFacilityData> =>
    apiPrivateAxiosWithBaseURL.get(`/facility/${getFacilityKey()}`, {
      baseURL: API_URL_ADMIN
    }),
};
