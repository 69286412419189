const APP_PATHS = {
  APP: "/app/",
  DASHBOARD: "dashboard",
  ADMINISTRATION: "administration",
  STATE: "state",
  TWOFECT: "two-factor",
  SERVICES: "services",
  APPOINTMENTS: "appointments",
  USERS: "users",
  PATIENTS: "patients",
  CREATE_PATIENT: "patient/create",
  EDIT_PATIENT: "patient/edit",
  CREATE_USER: "user/create",
  EDIT_USER: "user/edit",
  SEND_ADT: "send-adt",
  REPORTS: "reports",
  GENERAL: "general",
  PATIENT: "patient",
  PREVIEW: "preview",
  NOTIFICATIONS: "notification",
  ASSIGNMENTS: "assignments",
  CLIENT_INFORMATION: "client-information",
  NEW_PASSWORD: "new-password",
};

export { APP_PATHS };
