import { AxiosResponseHeaders } from "axios";
import { IParams, Order } from "./types";
import { apiPrivateAxios, apiPrivateAxiosWithHeaders } from "../../axios";
import { INotificationItem } from "../../../store/notifications/types";

const notificationRequest = {
  getNotifications: (
    params?: IParams
  ): Promise<{ data: INotificationItem[]; headers: AxiosResponseHeaders }> =>
    apiPrivateAxiosWithHeaders.get("/notification/list", {
      params: {
        ...params,
        total: true,
        size: params?.size || 20,
        sort: params?.sort || "createdOn",
        order: params?.order || Order.Desc,
      },
    }),
  getNotificationsCount: (): Promise<number> =>
    apiPrivateAxios.get("/notification/count"),
  readNotification: (notificationId: string): Promise<void> =>
    apiPrivateAxios.patch(`/notification?notificationId=${notificationId}`),
  deleteNotification: (notificationId: string): Promise<void> =>
    apiPrivateAxios.delete(`/notification?notificationId=${notificationId}`),
};

export default notificationRequest;
