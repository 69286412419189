import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api/index";
import { IError } from "../types/common";
import {
  IOfficeItem,
  IOfficeSelector,
  ICreateOffice,
} from "../../api/services/officeRequest/types";
import { showSuccessNotify } from "../../utils/notify";

export const getOffices = createAsyncThunk<
  IOfficeItem[],
  void,
  { rejectValue: IError }
>("officeSlice/getOffices", async (_, { rejectWithValue }) => {
  try {
    return await API.getOffice();
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const getOfficeSelectors = createAsyncThunk<
  IOfficeSelector[],
  void,
  { rejectValue: IError }
>("officeSlice/getOfficeSelectors", async (_, { rejectWithValue }) => {
  try {
    return await API.getOfficeSelectors();
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const addOffice = createAsyncThunk<
  ICreateOffice,
  ICreateOffice,
  { rejectValue: IError }
>("officeSlice/office", async (data, { rejectWithValue }) => {
  try {
    await API.createOffice(data);
    return data;
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const updateOffice = createAsyncThunk<
  ICreateOffice,
  ICreateOffice,
  { rejectValue: IError }
>("officeSlice/updateOffice", async (data, { rejectWithValue }) => {
  try {
    await API.updateOffice(data);
    showSuccessNotify("Data has been successfully updated");
    return data;
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const deleteOffice = createAsyncThunk<
  void,
  string,
  { rejectValue: IError }
>("officeSlice/deleteOffice", async (officeId, { rejectWithValue }) => {
  try {
    await API.deleteOffice(officeId);
    showSuccessNotify("Office has been successfully deleted");
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});
