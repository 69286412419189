import { createSlice } from "@reduxjs/toolkit";
import { IState } from "./types";
import {
  createStatesService,
  deleteStatesService,
  getState,
  updateStatesService,
} from "./thunks";

const initialState: IState = {
  state: {
    description: "",
    id: "",
    medicalCredentials: [],
    name: "",
    services: [],
    status: "",
  },
  error: null,
  status: "idle",
};

export const stateSlice = createSlice<IState, {}>({
  name: "stateSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getState.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getState.fulfilled, (state, { payload }) => {
      state.status = "";
      state.error = null;
      state.state = payload;
    });
    builder.addCase(deleteStatesService.fulfilled, (state, { payload }) => {
      state.error = null;
      if (state.state.services) {
        state.state.services = [
          ...state.state.services.filter((item) => item.id !== payload.id),
        ];
      }
    });
    builder.addCase(updateStatesService.fulfilled, (state, { payload }) => {
      state.error = null;
      if (state.state.services) {
        state.state.services = state.state.services.map((e) =>
          e.id === payload.id ? payload : e
        );
      }
    });
    builder.addCase(createStatesService.fulfilled, (state, { payload }) => {
      state.error = null;
      if (state.state.services) {
        state.state.services = [...state.state.services, payload];
      }
    });
    builder.addCase(getState.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.status = "idle";
    });
  },
});

export default stateSlice.reducer;
