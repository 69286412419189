import { createSlice } from "@reduxjs/toolkit";
import { IState } from "./types";
import { getGeneralReports } from "./thunks";

const initialState: IState = {
  reports: {
    completedServiceItems: {
      gridItems: [],
      charPercentage: 0,
      totalCount: 0,
    },
    notCompletedServiceItems: {
      gridItems: [],
      charPercentage: 0,
      totalCount: 0,
    },
    canceledServiceItems: {
      gridItems: [],
      charPercentage: 0,
      totalCount: 0,
    },
    rescheduledServiceItems: {
      gridItems: [],
      charPercentage: 0,
      totalCount: 0,
    },
    from: "",
    to: "",
  },
  error: null,
  status: "idle",
};

export const reportsSlice = createSlice<IState, {}>({
  name: "reportsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGeneralReports.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getGeneralReports.fulfilled, (state, { payload }) => {
      state.status = "";
      state.error = null;
      state.reports = payload;
    });
    builder.addCase(getGeneralReports.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.status = "idle";
    });
  },
});

export default reportsSlice.reducer;
