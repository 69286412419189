import { createSlice } from "@reduxjs/toolkit";
import { IStates } from "./types";
import { createState, deleteState, getStatesList } from "./thunks";

const initialState: IStates = {
  states: [
    {
      description: "",
      id: "",
      medicalCredentials: [],
      name: "",
      services: [],
      status: "",
    },
  ],
  error: null,
  status: "idle",
};

export const statesSlice = createSlice<IStates, {}>({
  name: "statesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatesList.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getStatesList.fulfilled, (state, { payload }) => {
      state.error = null;
      state.states = payload;
    });
    builder.addCase(createState.fulfilled, (state, { payload }) => {
      state.error = null;
      state.states = [...state.states, payload];
    });
    builder.addCase(deleteState.fulfilled, (state, { payload }) => {
      state.error = null;
      state.states = [...state.states.filter((item) => item.id !== payload.id)];
    });
    builder.addCase(getStatesList.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.status = "idle";
    });
  },
});

export default statesSlice.reducer;
