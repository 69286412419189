import { createSlice } from "@reduxjs/toolkit";
import { getOffices, getOfficeSelectors } from "./thunk";
import { IOfficeState } from "./types";

const initialState: IOfficeState = {
  error: null,
  status: "idle",
  officeItem: [],
  officeSelectors: [],
};

export const officeSlice = createSlice<IOfficeState, {}>({
  name: "officeSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOffices.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getOffices.fulfilled, (state, { payload }) => {
      state.error = null;
      state.officeItem = payload;
    });

    builder.addCase(getOffices.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.status = "idle";
    });
    builder.addCase(getOfficeSelectors.fulfilled, (state, { payload }) => {
      state.officeSelectors = payload;
    });
    // TODO : Create AddOffice reducer
    // TODO : Create UpdateOffice reducer
  },
});

export default officeSlice.reducer;
