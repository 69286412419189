const typography = {
  fontFamily: "'Poppins', sans-serif",
  h1: {
    fontWeight: 600,
    fontSize: "3.125rem",
    lineHeight: "3.5rem",
  },
  h2: {
    fontSize: "1.25rem",
    fontWeight: 400,
    lineHeight: "1.5rem",
  },
  body1: {
    fontSize: "0.938rem",
    fontWeight: 300,
    lineHeight: "1.125rem",
  },
  body2: {
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "1rem",
  },
  h3: {
    fontSize: "2.125rem",
    fontWeight: 600,
    lineHeight: 1.235,
  },
  h4: {
    fontSize: "1.5rem",
    fontWeight: 700,
    lineHeight: 1.334,
  },
  h5: {
    fontSize: "1.125rem",
    fontWeight: 600,
    lineHeight: 1.6,
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: 1.5,
  },
  subtitle1: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: 1.1,
  },
  subtitle2: {
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: 1.1,
  },
};

export default typography;
