import { createAsyncThunk } from "@reduxjs/toolkit";
import { IError } from "../types/common";
import {
  PayloadType,
  preparePayload,
} from "../../components/layouts/ReportsLayout/helper";
import { IReport } from "../../components/layouts/ReportsLayout/form/types";
import { IPatientReports, IReports } from "./types";

export const getGeneralReports = createAsyncThunk<
  IReports,
  { values: IReport },
  { rejectValue: IError }
>("reportsSlice/getGeneralReports", async (payload, { rejectWithValue }) => {
  try {
    return (await preparePayload(
      payload.values,
      PayloadType.generalPreview
    )) as IReports;
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const getPatientReports = createAsyncThunk<
  IPatientReports,
  { values: IReport },
  { rejectValue: IError }
>(
  "patientReportsSlice/getPatientReports",
  async (payload, { rejectWithValue }) => {
    try {
      return (await preparePayload(
        payload.values,
        PayloadType.patientPreview
      )) as IPatientReports;
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);
