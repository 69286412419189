import { createAsyncThunk } from "@reduxjs/toolkit";

import API from "../../api";
import { showErrorNotify, showSuccessNotify } from "../../utils/notify";
import { getUserId } from "../../utils/token";
import { setTokenData, setVerifiedState } from "../../helpers/token";

import { APP_PATHS } from "../../pages/app/routes/constants";
import { IError } from "../types/common";
import { IOTPData, ITokenData } from "../../api/services/authRequest/types";
import { ICurrentUser } from "../../api/services/usersRequests/types";
import {
  IGenerateOTPPayload,
  IGenerateOTPVerificationPayload,
  ILogInPayload,
} from "./types";
import { AUTH_PATHS } from "../../pages/auth/routes/constants";

export const logIn = createAsyncThunk<
  ITokenData,
  ILogInPayload,
  { rejectValue: IError }
>(
  "authSlice/logIn",
  async (
    { userName, password, navigate, facilityKey },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const data = await API.logIn({
        userName,
        password,
        facilityKey,
      });

      setTokenData(data);
      if (!data?.verified) {
        navigate(`${AUTH_PATHS.AUTH}${AUTH_PATHS.TWO_FACTOR}`);
      } else {
        setVerifiedState("verified");
        dispatch(getAuthUser());
        navigate(`${APP_PATHS.APP}${APP_PATHS.DASHBOARD}`);
      }

      return data;
    } catch (error) {
      showErrorNotify(error);
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);

export const getAuthUser = createAsyncThunk<
  ICurrentUser | null,
  void,
  { rejectValue: IError }
>("authSlice/getAuthUser", async (_, { rejectWithValue }) => {
  try {
    const userId = getUserId();
    if (!userId) return null;

    return await API.getCurrentUser();
  } catch (error) {
    showErrorNotify(error);
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const generateOtp = createAsyncThunk<
  ITokenData,
  IGenerateOTPPayload,
  { rejectValue: IError }
>(
  "authSlice/generateOtp",
  async ({ method, authorityOrigin, accessToken }, { rejectWithValue }) => {
    try {
      const data = await API.generateOtp({
        method,
        authorityOrigin: "WEB",
        accessToken,
      });
      setTokenData(data);
      showSuccessNotify("OTP sent successfully");
      return data;
    } catch (err) {
      showErrorNotify(err);
      const { data } = err as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);

export const verifyOTP = createAsyncThunk<
  ITokenData,
  IGenerateOTPVerificationPayload,
  { rejectValue: IError }
>(
  "authSlice/verifyOTPToken",
  async (
    { otp, accessToken, navigate, authorityOrigin, isRememberMe },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const data = await API.verifyOTPToken({
        otp,
        accessToken,
        isRememberMe,
        authorityOrigin: "WEB",
      });
      setTokenData(data);
      setVerifiedState("verified");
      dispatch(getAuthUser());
      navigate(`${APP_PATHS.APP}${APP_PATHS.DASHBOARD}`);
      return data;
    } catch (err) {
      showErrorNotify(err);
      const { data } = err as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);
