import { createSlice } from "@reduxjs/toolkit";
import { IStatesSelector } from "./types";
import { getStatesSelectors } from "./thunks";

const initialState: IStatesSelector = {
  stateSelectors: [],
  error: null,
  status: "idle",
};

export const statesSelectorsSlice = createSlice<IStatesSelector, {}>({
  name: "statesSelectorsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatesSelectors.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getStatesSelectors.fulfilled, (state, { payload }) => {
      state.error = null;
      state.stateSelectors = payload;
    });
    builder.addCase(getStatesSelectors.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.status = "idle";
    });
  },
});

export default statesSelectorsSlice.reducer;
