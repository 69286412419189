import { createSlice } from "@reduxjs/toolkit";
import { logIn, getAuthUser } from "./thunks";
import { IAuthState, IUser } from "./types";

const initialState: IAuthState = {
  user: null,
  error: null,
  status: "idle",
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    reset: (state) => {
      state.user = initialState.user;
      state.error = initialState.error;
      state.status = initialState.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logIn.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(logIn.fulfilled, (state) => {
      state.error = null;
    });
    builder.addCase(logIn.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.status = "idle";
    });
    builder.addCase(getAuthUser.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getAuthUser.fulfilled, (state, { payload }) => {
      let user: IUser | null = null;

      if (payload) {
        user = {
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          previewPhotoFileKey: payload.previewPhotoFileKey,
        };
      }

      state.user = user;
      state.error = null;
    });
    builder.addCase(getAuthUser.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.status = "idle";
    });
  },
});

export default authSlice.reducer;
