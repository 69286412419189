import { AxiosResponseHeaders } from "axios";
import qs from "qs";
import { apiPrivateAxios, apiPrivateAxiosWithHeaders } from "../../axios";
import { pageSize } from "../../../utils/commonConstants";
import { IParams } from "../../../store/types/common";
import { IUsers } from "../../../store/users/types";
import {
  ICurrentUser,
  IUser,
  ICreateUser,
  IUpdateUser,
  IUserSelector,
} from "./types";
import { IGetParams } from "../../types";

export const usersRequests = {
  getCurrentUser: (): Promise<ICurrentUser> =>
    apiPrivateAxios.get("user/current"),
  getUser: (id: string): Promise<IUser> => apiPrivateAxios.get(`/user/${id}`),
  getUserCsv: (data: { [k: string]: string }): Promise<Blob> =>
    apiPrivateAxios.get("/user/batch?status=Active&status=Inactive", {
      params: data,
    }),
  createUser: (data: ICreateUser): Promise<ICreateUser> =>
    apiPrivateAxios.post("/user", data),
  updateUser: (id: string, data: IUpdateUser): Promise<IUpdateUser> =>
    apiPrivateAxios.put(`/user/${id}`, data),
  updateUserStatus: (data: { ids: string[]; status: string }): Promise<void> =>
    apiPrivateAxios.put("/user/status", data),
  
  updateUserRole: (id: string): Promise<void> =>
    apiPrivateAxios.post("/user/role", {id}),
  uploadUserCsv: (data: FormData): Promise<IUsers[]> =>
    apiPrivateAxios.post("/user/batch", data),
  getUsers: (
    params?: IParams
  ): Promise<{ data: IUsers[]; headers: AxiosResponseHeaders }> =>
    apiPrivateAxiosWithHeaders.get("/user?status=Active&status=Inactive", {
      params: { ...params, size: params?.size, total: true },
    }),
  getUsersSelectors: (params: IGetParams = {}): Promise<IUserSelector[]> =>
    apiPrivateAxios.get("/user/selector", {
      params,
      paramsSerializer: (paramsData) =>
        // TODO: replace to the custom method (not use qs)
        qs.stringify(paramsData, { arrayFormat: "repeat" }),
    }),
};

export default { usersRequests };
