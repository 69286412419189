/* eslint-disable @typescript-eslint/no-explicit-any */
interface IParseJSON {
  [key: string]: any;
}

const parseJSON = (str: string): IParseJSON => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
};

export default parseJSON;
