import { apiPrivateAxios } from "../../axios";
import {
  IParams,
  IQuickData,
  IServicesData,
} from "../../../store/dashboard/types";
import {
  IGetPerformanceParams,
  IGetPerformanceChangeParams,
  TPerformance,
} from "./types";

export const dashboardRequests = {
  getQuickData: (params?: IParams): Promise<IQuickData> =>
    apiPrivateAxios.get("/dashboard/quick-data", {
      params,
    }),
  getServicesData: (params?: IParams): Promise<IServicesData> =>
    apiPrivateAxios.get(`/dashboard/services`, {
      params,
    }),
  getPerformanceData: (params: IGetPerformanceParams): Promise<TPerformance> =>
    apiPrivateAxios.get("/dashboard/performance", { params }),
  getPerformanceChangeData: (
    params: IGetPerformanceChangeParams
  ): Promise<TPerformance> =>
    apiPrivateAxios.get("/dashboard/performance-change", { params }),
};

export default dashboardRequests;
