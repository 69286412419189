import { apiPrivateAxios } from "../../axios";
import { IOfficeItem, IOfficeSelector, ICreateOffice } from "./types";

export const officeRequest = {
  getOffice: (): Promise<IOfficeItem[]> => apiPrivateAxios.get("/office"),
  getOfficeSelectors: (): Promise<IOfficeSelector[]> =>
    apiPrivateAxios.get("/office/selector"),
  createOffice: (data: ICreateOffice): Promise<ICreateOffice> =>
    apiPrivateAxios.post("/office/office", data),
  updateOffice: (data: ICreateOffice): Promise<ICreateOffice> =>
    apiPrivateAxios.put(`/office/${data.officeSelect}`, {
      id: data.officeSelect,
      ...data,
    }),
  deleteOffice: (id: string): Promise<void> =>
    apiPrivateAxios.delete(`/office/${id}`),
};
