import { createSlice } from "@reduxjs/toolkit";
import {
  deleteNotification,
  getNotifications,
  getNotificationsCount,
  readNotification,
} from "./thunk";
import { INotifications } from "./types";

const initialState: INotifications = {
  notifications: [],
  notificationsCount: 0,
  totalCount: 0,
  notificationStartList: 0,
  responseStatus: "idle",
  error: null,
};

export const notificationsSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.responseStatus = "loading";
      state.error = null;
    });
    builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
      state.error = null;
      state.notifications = payload?.notificationStartList
        ? [...state.notifications, ...payload.data]
        : payload.data;
      state.totalCount = payload.totalCount;
      state.notificationStartList = payload?.notificationStartList;
      state.responseStatus = "idle";
    });
    builder.addCase(getNotificationsCount.fulfilled, (state, { payload }) => {
      state.error = null;
      state.notificationsCount = payload;
    });

    builder.addCase(readNotification.fulfilled, (state, { payload: id }) => {
      state.error = null;
      state.notifications = state.notifications.map((notification) =>
        notification.id === id
          ? { ...notification, readStatus: "Read" }
          : notification
      );
    });

    builder.addCase(deleteNotification.fulfilled, (state, { payload: id }) => {
      state.error = null;
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== id
      );
    });

    builder.addCase(getNotifications.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.responseStatus = "idle";
    });
  },
});

export default notificationsSlice.reducer;
