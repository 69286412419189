export enum Order {
  Asc = "Asc",
  Desc = "Desc",
}

export interface IParams {
  id?: string;
  start?: number;
  size?: number;
  sort?: string;
  order?: Order;
  total?: boolean;
  read?: boolean | string;
  startDate?: string;
  patientId?: string;
  clinicianId?: string;
  endDate?: string;
  type?: string;
}
