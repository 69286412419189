import API from "../api";
import {
  getLocalTokenData,
  setLocalData,
  setVerifiedStateListing,
} from "../api/services/localData/tokenActions";
import { getDecodedJwt } from "../utils/token";
import { ITokenData } from "../api/services/authRequest/types";

export const isValidToken = (): boolean => {
  const decodedJwt = getDecodedJwt();
  const expTime = decodedJwt.exp;

  if (!expTime) return false;
  return Date.now() < expTime * 5000;
};

export const updateToken = async (): Promise<{
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
}> => {
  const token = getLocalTokenData();

  return API.updateToken({
    accessToken: token?.accessToken || "",
    refreshToken: token?.refreshToken || "",
    grantType: "refresh_token",
  });
};

export const setTokenData = (tokenData: ITokenData): void => {
  setLocalData({
    accessToken: tokenData.access_token,
    refreshToken: tokenData.refresh_token,
    expireIn: tokenData.expires_in,
    refreshTokenExpireIn: tokenData.refresh_token_expires_in,
  });
};

export const setVerifiedState = (state: string): void => {
  setVerifiedStateListing(state);
};
