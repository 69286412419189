import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api/index";
import { IError } from "../types/common";
import { IDesignationSelector } from "./types";
import { IGetParams } from "../../api/types";

export const getStateDesignationSelectors = createAsyncThunk<
  IDesignationSelector[],
  IGetParams | undefined,
  { rejectValue: IError }
>(
  "stateDesignation/getStateDesignationSelectors",
  async (params, { rejectWithValue }) => {
    try {
      return await API.getStateDesignationSelectors(params);
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);
