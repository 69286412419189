import createTheme from "@mui/material/styles/createTheme";
import typography from "./typography";

export const palette = createTheme({
  // TODO: add correct colors
  typography,
  palette: {
    primary: {
      light: "#E0EAFB",
      main: "#2076F2",
      dark: "#1F76F2",
    },
    secondary: {
      light: "#F4F5F6",
      main: "#878787",
      dark: "#8E8E8E",
    },
    text: {
      primary: "#27334B",
      secondary: "#9E9E9E",
    },
    divider: "#D8D8D8",
    background: {
      default: "#F2F2F2",
    },
    info: {
      light: "#dee9fb",
      main: "#858e9c",
    },
  },
});

export default palette;
