import API from "../../../api";
import { IReport } from "./form/types";
import { IPatientReports, IReports } from "../../../store/reports/types";

export enum PayloadType {
  general = "general",
  generalPreview = "generalPreview",
  patient = "patient",
  patientPreview = "patientPreview",
}
export const preparePayload = async (
  values: IReport,
  payloadType: PayloadType
): Promise<Blob | IReports | IPatientReports> => {
  const generalData = {
    reportType: values.reportType,
    reportFormat: values.reportFormat,
    from: values.from,
    to: values.to,
    clinicianId: values?.clinicians?.value,
    "reportSections.completed.PieChart": values.completedPieChart ?? false,
    "reportSections.completed.Grid": values.completedGrid ?? false,
    "reportSections.notCompleted.PieChart":
      values.notCompletedPieChart ?? false,
    "reportSections.notCompleted.Grid": values.notCompletedGrid ?? false,
    "reportSections.canceled.PieChart": values.canceledPieChart ?? false,
    "reportSections.canceled.Grid": values.canceledGrid ?? false,
    "reportSections.rescheduled.PieChart": values.rescheduledPieChart ?? false,
    "reportSections.rescheduled.Grid": values.rescheduledGrid ?? false,
    "reportSections.longitudinalReport": values.longitudinalReport ?? false,
    "reportSections.readmissionPerPatient":
      values.readmissionPerPatient ?? false,
    "reportSections.readmissionsRelatedToDiagnosis":
      values.readmissionsRelatedToDiagnosis ?? false,
    "reportSections.completionsOfMedicationReconciliations":
      values.completionsOfMedicationReconciliations ?? false,
    "reportSections.reconciliationsCompletion":
      values.reconciliationsCompletion ?? false,
  };
  const patientData = {
    reportFormat: values.reportFormat,
    patientId: values.patients?.value,
    from: values.from,
    to: values.to,
    "ReportSections.servicesSummary": values.servicesSummary,
    "ReportSections.patientStatesHistory": values.patientStatesHistory,
    "ReportSections.daysOfTreatment": values.daysOfTreatment,
    "ReportSections.stage": values.stage,
    "ReportSections.attendingClinicians": values.attendingClinicians,
    "ReportSections.assignedPhysicians": values.assignedPhysicians,
    "ReportSections.diagnosisOverview": values.diagnosisOverview,
  };
  if (payloadType === PayloadType.generalPreview) {
    return API.getGeneralReports(generalData);
  }
  if (payloadType === PayloadType.patientPreview) {
    return API.getPatientReports(patientData);
  }
  return payloadType === PayloadType.general
    ? API.getGeneralReportFile(generalData)
    : API.getPatientReportFile(patientData);
};
