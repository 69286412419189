import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api/index";
import { IError } from "../types/common";
import { IAdminFacility } from "./types";
import { showErrorNotify } from "../../utils/notify";

export const getAdminFacilityData = createAsyncThunk<
  IAdminFacility,
  void,
  { rejectValue: IError }
>("adminFacilitySlice/getAdminFacilityData", async (_, { rejectWithValue }) => {
  try {
    const response = await API.getFacilityAdminDetails();
    return response;
  } catch (err) {
    showErrorNotify(err);
    const { data } = err as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});
