import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  loaderWrapper: {
    display: "flex",
    paddingLeft: 10,
  },
  loader: {
    color: "white",
  },
}));
