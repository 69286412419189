import { apiPrivateAxios } from "../../axios";
import {
  IGetOptimizedAssignments,
  IAssignments,
  IHistory,
  ISaveAssignmentSuggestionRequest,
} from "./types";

export const assignmentsOptimizationRequest = {
  getOptimizedAssignments: (
    params: IGetOptimizedAssignments
  ): Promise<IAssignments> =>
    apiPrivateAxios.get("/assignments-optimization/optimized", {
      params,
    }),
  getAssignmentsHistory: (): Promise<IHistory> =>
    apiPrivateAxios.get("/assignments-optimization/history", {
      params: { sort: "createDate", order: "Desc" },
    }),
  saveAssignmentSuggestions: (
    data: ISaveAssignmentSuggestionRequest
  ): Promise<void> => apiPrivateAxios.put("/assignments-optimization", data),
  getAssignmentCsv: (versionId: string): Promise<Blob> =>
    apiPrivateAxios.get("/assignments-optimization/history-csv", {
      params: { versionId },
    }),
};

export default { assignmentsOptimizationRequest };
