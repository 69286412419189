import { AxiosResponseHeaders } from "axios";
import { apiPrivateAxios, apiPrivateAxiosWithHeaders } from "../../axios";
import { IGetParams } from "../../types";
import { IParams } from "../../../store/types/common";
import { IPatients, IPatientSelector } from "../../../store/patients/types";
import {
  IPatient,
  ICreatePatient,
  IUpdatePatient,
  IPatientServiceStates,
} from "./types";

export const patientsRequests = {
  getPatient: (id: string): Promise<IPatient> =>
    apiPrivateAxios.get(`/patient/${id}`),
  createPatient: (data: ICreatePatient): Promise<ICreatePatient> =>
    apiPrivateAxios.post("/patient", data),
  updatePatient: (id: string, data: IUpdatePatient): Promise<IUpdatePatient> =>
    apiPrivateAxios.put(`/patient/${id}`, data),
  getPatients: (
    params?: IParams
  ): Promise<{ data: IPatients[]; headers: AxiosResponseHeaders }> =>
    apiPrivateAxiosWithHeaders.get("/patient?status=Active&status=Inactive", {
      params: { ...params, size: params?.size, total: true },
    }),
  updatePatientStatus: (data: {
    ids: string[];
    status: string;
  }): Promise<void> => apiPrivateAxios.put("/patient/status", data),
  updatePatientAcuity: (data: {
    ids: string[];
    acuity: string;
  }): Promise<void> => apiPrivateAxios.put("/patient/acuity", data),
  updatePatientHomebound: (data: {
    ids: string[];
    homebound: boolean;
  }): Promise<void> => apiPrivateAxios.put("/patient/homebound", data),
  updatePatientAwv: (data: { ids: string[]; awv: boolean }): Promise<void> =>
    apiPrivateAxios.put("/patient/awv", data),
  updatePatientService: (data: {
    ids: string[];
    actionItem: string;
    states: IPatientServiceStates[];
  }): Promise<void> => apiPrivateAxios.put("/patient/service", data),
  getPatientSelector: (params?: IGetParams): Promise<IPatientSelector[]> =>
    apiPrivateAxios.get("/patient/selector", { params }),
  uploadPatientCsv: (data: FormData): Promise<IPatients[]> =>
    apiPrivateAxios.post("/patient/batch", data),
  getPatientCsv: (data: { [k: string]: string }): Promise<Blob> =>
    apiPrivateAxios.get("/patient/batch?status=Active&status=Inactive", {
      params: data,
    }),
  getPatientById: (id: string | null): Promise<IPatients> =>
    apiPrivateAxios.get(`/patient/${id}`),
};

export default { patientsRequests };
