import { IServerErrorResponse } from "../api/types";

export const extractErrorMessage = (
  error: Error | IServerErrorResponse | string
): string => {
  if (typeof error === "string") return error;
  if (error instanceof Error) {
    return error.message;
  }

  // Try to handle as ServerError
  const errors = error?.data?.errors || {};
  const title = error?.data?.title;
  let message = "";

  Object.keys(errors).forEach((messageType) => {
    errors[messageType].forEach((msg: string) => {
      message += ` ${msg} \n`;
    });
  });

  if (message) return message;
  if (title) return title;

  return "Unknown error occured";
};
