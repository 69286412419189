import { IServiceSelector } from "../../../store/services/types";
import { IServiceItems } from "../../../store/types/state";
import { apiPrivateAxios } from "../../axios";
import { IGetParams } from "../../types";

export const servicesRequest = {
  getService: (id: string): Promise<IServiceItems> =>
    apiPrivateAxios.get(`/service/${id}`),
  createService: (data: IServiceItems): Promise<IServiceItems> =>
    apiPrivateAxios.post(`/service/`, data),
  updateService: (data: IServiceItems): Promise<IServiceItems> =>
    apiPrivateAxios.put(`/service/${data.id}`, data),
  deleteService: (id: string): Promise<void> =>
    apiPrivateAxios.delete(`/service`, { data: { id } }),
  getServiceSelectors: (params: IGetParams): Promise<IServiceSelector[]> =>
    apiPrivateAxios.get("/service/selector", { params }),
};

export default { servicesRequest };
