import { createSlice } from "@reduxjs/toolkit";
import { IState } from "./types";
import {
  getQuickData,
  getServicesData,
  getPerformanceData,
  getPerformanceChangeData,
} from "./thunks";

const initialState: IState = {
  quickData: {
    patientReadmissions: {
      data: [],
      percent: 0,
      amount: 0,
      total: 0,
      trend: 0,
    },
    completedFromMedicalReconcilations: {
      data: [],
      percent: 0,
      amount: 0,
      total: 0,
      trend: 0,
    },
    completedFromServiceDue: {
      data: [],
      percent: 0,
      amount: 0,
      total: 0,
      trend: 0,
    },
  },
  servicesData: null,
  performanceData: [],
  performanceChangeData: [],
  error: null,
  status: "idle",
};

export const dashboardSlice = createSlice<IState, {}>({
  name: "reportsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getQuickData.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getQuickData.fulfilled, (state, { payload }) => {
      state.status = "";
      state.error = null;
      state.quickData = payload;
    });
    builder.addCase(getServicesData.fulfilled, (state, { payload }) => {
      state.status = "";
      state.error = null;
      state.servicesData = payload;
    });
    builder.addCase(getQuickData.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.status = "idle";
    });
    builder.addCase(getPerformanceData.fulfilled, (state, { payload }) => {
      state.status = "";
      state.error = null;
      state.performanceData = payload;
    });
    builder.addCase(getPerformanceData.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.status = "idle";
      state.performanceData = [];
    });
    builder.addCase(
      getPerformanceChangeData.fulfilled,
      (state, { payload }) => {
        state.status = "";
        state.error = null;
        state.performanceChangeData = payload;
      }
    );
    builder.addCase(getPerformanceChangeData.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.status = "idle";
      state.performanceChangeData = [];
    });
  },
});

export default dashboardSlice.reducer;
