import { apiPrivateAxios } from "../../axios";
import { IPatientReports, IReports } from "../../../store/reports/types";
import { IReport } from "../../../components/layouts/ReportsLayout/form/types";

export const reportsRequest = {
  getGeneralReports: (data: IReport): Promise<IReports> =>
    apiPrivateAxios.get("/reports-data/general-report", {
      params: data,
    }),
  getPatientReports: (data: IReport): Promise<IPatientReports> =>
    apiPrivateAxios.get("/reports-data/patient-report", {
      params: data,
    }),
};

export default { statesRequest: reportsRequest };
