import { createTheme } from "@mui/material";
import { Theme } from "@mui/material/styles/createTheme";

import getOverrides from "./overrides";

export const createCustomTheme = (theme: Theme): Theme =>
  createTheme({
    ...theme,
    components: getOverrides(theme),
  });
