import { FC, ReactNode } from "react";

import { ThemeProvider as MUIThemeProvider } from "@mui/material";
import { theme } from "../themes";

interface IProps {
  children: ReactNode;
}

const ThemeProvider: FC<IProps> = ({ children }) => {
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};

export default ThemeProvider;
