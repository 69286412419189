import { createAsyncThunk } from "@reduxjs/toolkit";

import API from "../../api/index";
import { showErrorNotify, showSuccessNotify } from "../../utils/notify";
import { IGetParams } from "../../api/types";
import { IError, IErrorCsvData, IParams } from "../types/common";
import { IPatients, IPatientSelector } from "./types";
import { IPatientServiceStates } from "../../api/services/patientsRequests/types";

export const getPatients = createAsyncThunk<
  {
    data: IPatients[];
    patientsNumber: number;
    params?: IParams;
    isSorted?: boolean;
  },
  { params?: IParams; isSorted?: boolean } | undefined,
  { rejectValue: IError }
>("patientsSlice/getPatients", async (payload, { rejectWithValue }) => {
  try {
    const { headers, data } = await API.getPatients(payload?.params);
    const usersNumber = headers["x-total-count"];
    return {
      data,
      patientsNumber: +usersNumber,
      params: payload?.params,
      isSorted: payload?.isSorted,
    };
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const updatePatientStatus = createAsyncThunk<
  { ids: string[]; status: string },
  { ids: string[]; status: string },
  { rejectValue: IError }
>("patientsSlice/updatePatientStatus", async (payload, { rejectWithValue }) => {
  try {
    await API.updatePatientStatus(payload);
    showSuccessNotify("Data has been successfully updated");
    return payload;
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const updatePatientAcuity = createAsyncThunk<
  { ids: string[]; acuity: string },
  { ids: string[]; acuity: string },
  { rejectValue: IError }
>("patientsSlice/updatePatientAcuity", async (payload, { rejectWithValue }) => {
  try {
    await API.updatePatientAcuity(payload);
    showSuccessNotify("Data has been successfully updated");
    return payload;
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const updatePatientHomebound = createAsyncThunk<
  { ids: string[]; homebound: boolean },
  { ids: string[]; homebound: boolean },
  { rejectValue: IError }
>(
  "patientsSlice/updatePatientHomebound",
  async (payload, { rejectWithValue }) => {
    try {
      await API.updatePatientHomebound(payload);
      showSuccessNotify("Data has been successfully updated");
      return payload;
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);

export const updatePatientAwv = createAsyncThunk<
  { ids: string[]; awv: boolean },
  { ids: string[]; awv: boolean },
  { rejectValue: IError }
>("patientsSlice/updatePatientAwv", async (payload, { rejectWithValue }) => {
  try {
    await API.updatePatientAwv(payload);
    showSuccessNotify("Data has been successfully updated");
    return payload;
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const updatePatientService = createAsyncThunk<
  { ids: string[]; actionItem: string; states: IPatientServiceStates[] },
  { ids: string[]; actionItem: string; states: IPatientServiceStates[] },
  { rejectValue: { errMsg: string } | IError }
>(
  "patientsSlice/updatePatientService",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await API.updatePatientService(payload);
      if (Array.isArray(res) && res.length > 0) {
        const patientNames = res
          .map((patient) => `${patient.firstName} ${patient.lastName}`)
          .join(", ");
        const errMsg = `Could not assign program / service to the following patient(s) because their clinician does not have the program / service assigned: ${patientNames}`;
        return rejectWithValue({ errMsg });
      } else {
        showSuccessNotify("Data has been successfully updated");
        return payload;
      }
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);

export const selectedAllPatients = createAsyncThunk<
  { selected: boolean },
  { selected: boolean },
  { rejectValue: IError }
>("patientsSlice/selectedAllPatients", (payload, { rejectWithValue }) => {
  try {
    return payload;
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const getPatientSelector = createAsyncThunk<
  IPatientSelector[],
  IGetParams | undefined,
  { rejectValue: IError }
>("patientsSlice/ getPatientSelector", async (params, { rejectWithValue }) => {
  try {
    return await API.getPatientSelector({
      ...params,
      size: params?.size || 1000,
    });
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const uploadPatientCsv = createAsyncThunk<
  IPatients[],
  { csvData: FormData },
  { rejectValue: IErrorCsvData }
>(
  "patientsSlice/uploadPatientCsv",
  async ({ csvData }, { rejectWithValue }) => {
    try {
      const data = await API.uploadPatientCsv(csvData);
      showSuccessNotify("CSV successfully created");
      return data;
    } catch (error) {
      const { data } = error as { data: IErrorCsvData };
      return rejectWithValue({ ...data });
    }
  }
);

export const getPatientById = createAsyncThunk<
  IPatients,
  { id: string },
  { rejectValue: IError }
>("patientsSlice/getPatientById", async ({ id }, { rejectWithValue }) => {
  try {
    return await API.getPatientById(id);
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});
