import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api/index";
import { showSuccessNotify } from "../../utils/notify";
import { IError, IErrorCsvData, IParams } from "../types/common";
import { IUsers, IUsersSelector } from "./types";
import { IGetParams } from "../../api/types";

export const getUsers = createAsyncThunk<
  { data: IUsers[]; usersNumber: number; params?: IParams; isSorted?: boolean },
  { params?: IParams; isSorted?: boolean } | undefined,
  { rejectValue: IError }
>("usersSlice/getUsers", async (payload, { rejectWithValue }) => {
  try {
    const { data, headers } = await API.getUsers(payload?.params);
    const usersNumber = headers["x-total-count"];
    return {
      data,
      usersNumber: +usersNumber,
      params: payload?.params,
      isSorted: payload?.isSorted,
    };
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const getUsersSelectors = createAsyncThunk<
  IUsersSelector[],
  { params?: IGetParams } | void,
  { rejectValue: IError }
>("usersSlice/getUsersSelectors", async (payload, { rejectWithValue }) => {
  try {
    return await API.getUsersSelectors(payload?.params);
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

// TODO: move to "user" slice
export const updateUserStatus = createAsyncThunk<
  { ids: string[]; status: string },
  { ids: string[]; status: string },
  { rejectValue: IError }
>("usersSlice/updateUserStatus", async (payload, { rejectWithValue }) => {
  try {
    await API.updateUserStatus(payload);
    showSuccessNotify("Data has been successfully updated");
    return payload;
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const selectedAllUsers = createAsyncThunk<
  { selected: boolean },
  { selected: boolean },
  { rejectValue: IError }
>("usersSlice/selectedAllUsers", (payload, { rejectWithValue }) => {
  try {
    return payload;
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const uploadUserCsv = createAsyncThunk<
  IUsers[],
  { csvData: FormData },
  { rejectValue: IErrorCsvData }
>("usersSlice/uploadUserCsv", async ({ csvData }, { rejectWithValue }) => {
  try {
    const data = await API.uploadUserCsv(csvData);
    showSuccessNotify("CSV successfully created");
    return data;
  } catch (error) {
    const { data } = error as { data: IErrorCsvData };
    return rejectWithValue({ ...data });
  }
});
