import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api";
import { IError } from "../types/common";
import { IParams, IQuickData, IServicesData } from "./types";
import {
  IGetPerformanceParams,
  IGetPerformanceChangeParams,
  IPerformanceItem,
} from "../../api/services/dashboardRequests/types";

export const getQuickData = createAsyncThunk<
  IQuickData,
  { params?: IParams } | void,
  { rejectValue: IError }
>("dashboardSlice/getQuickData", async (payload, { rejectWithValue }) => {
  try {
    return await API.getQuickData(payload?.params);
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const getServicesData = createAsyncThunk<
  IServicesData,
  { params?: IParams } | void,
  { rejectValue: IError }
>("dashboardSlice/getServicesData", async (payload, { rejectWithValue }) => {
  try {
    return await API.getServicesData(payload?.params);
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const getPerformanceData = createAsyncThunk<
  IPerformanceItem[],
  IGetPerformanceParams,
  { rejectValue: IError }
>("dashboardSlice/getPerformanceData", async (payload, { rejectWithValue }) => {
  try {
    return await API.getPerformanceData(payload);
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const getPerformanceChangeData = createAsyncThunk<
  IPerformanceItem[],
  IGetPerformanceChangeParams,
  { rejectValue: IError }
>(
  "dashboardSlice/getPerformanceChangeData",
  async (payload, { rejectWithValue }) => {
    try {
      return await API.getPerformanceChangeData(payload);
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);
