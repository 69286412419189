import { createSlice } from "@reduxjs/toolkit";
import { getStateDesignationSelectors } from "./thunk";
import { IState } from "./types";

const initialState: IState = {
  error: null,
  responseStatus: "idle",
  designationSelectors: [],
};

// TODO: think to use states/stateSlice instead
export const stateDesignationSlice = createSlice<IState, {}>({
  name: "stateDesignationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getStateDesignationSelectors.fulfilled,
      (state, { payload }) => {
        state.designationSelectors = payload;
      }
    );
  },
});

export default stateDesignationSlice.reducer;
