import { createSlice } from "@reduxjs/toolkit";
import {
  getPatients,
  getPatientSelector,
  selectedAllPatients,
  uploadPatientCsv,
} from "./thunks";
import { IState } from "./types";

const initialState: IState = {
  patients: [],
  error: null,
  status: "idle",
  patientSelector: [],
  isSelected: false,
  patientsNumber: 0,
  params: {},
};

export const patientsSlice = createSlice({
  name: "patientsSlice",
  initialState,
  reducers: {
    resetParams: (state) => {
      state.params = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPatientSelector.fulfilled, (state, { payload }) => {
      state.patientSelector = payload;
    });
    builder.addCase(getPatients.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getPatients.fulfilled, (state, { payload }) => {
      state.error = null;
      state.patients = payload.data;
      state.patientsNumber = payload.patientsNumber;
      state.status = "idle";
      state.params = payload.params;
      state.isSorted = payload?.isSorted;
    });
    builder.addCase(uploadPatientCsv.fulfilled, (state, { payload }) => {
      state.error = null;
      state.patients = [...state.patients, ...payload];
    });
    builder.addCase(selectedAllPatients.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isSelected = payload.selected;
      state.patients = state.patients.map((e) => ({
        ...e,
        selected: payload.selected,
      }));
    });
    builder.addCase(getPatients.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.status = "idle";
    });
    builder.addCase(uploadPatientCsv.rejected, (state, { payload }) => {
      if (payload) {
        const errorList = Object.entries(payload.errors);
        state.error = errorList.length ? errorList : payload.title;
      }
      state.status = "idle";
    });
  },
});

export default patientsSlice.reducer;
