import React, { FC, useMemo } from "react";
import useStyles from "./styles";

export type Props = {
  value: string;
  valueLength: number;
  onChange: (value: string) => void;
};

export const RE_DIGIT = new RegExp(/^\d+$/);

const OtpInput: FC<Props> = ({ value, valueLength, onChange }) => {
  const valueItems = useMemo(() => {
    const valueArray = value.split("");
    const items: Array<string> = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [value, valueLength]);
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const inputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ): void => {
    const { target } = e;
    let targetValue = target.value;
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    const nextInputEl = target.nextElementSibling as HTMLInputElement | null;

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== "") {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : " ";

    const newValue =
      value.substring(0, idx) + targetValue + value.substring(idx + 1);

    onChange(newValue);

    if (!isTargetValueDigit) {
      return;
    }

    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;
    const targetValue = target.value;
    if (e.key !== "Backspace" || target.value !== "") {
      return;
    }

    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
    target.setSelectionRange(0, targetValue.length);
  };

  // eslint-disable-next-line consistent-return
  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { target } = e;
    const prevInputEl =
      target.previousElementSibling as HTMLInputElement | null;

    if (prevInputEl && prevInputEl.value === "") {
      return prevInputEl.focus();
    }
    target.setSelectionRange(0, target.value.length);
  };

  const classes = useStyles();
  return (
    <div className={classes.otpGroup}>
      {valueItems.map((item, index) => (
        <input
          key={index}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={valueLength}
          className={classes.otpInput}
          value={item}
          onChange={(e) => inputOnChange(e, index)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
        />
      ))}
    </div>
  );
};

export default OtpInput;
