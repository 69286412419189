import dayjs from "dayjs";
import { IStateDesignation } from "../store/patients/types";

export const toLocaleISOString = (date: Date | string): string =>
  date ? dayjs(date).format("YYYY-MM-DDTHH:MM:ssZ") : "";

export const formattedDate = (date: Date | string): string =>
  date ? dayjs(date).format("MM/DD/YYYY") : "";

export const formattedTime = (date: Date | string): string => {
  return date
    ? new Date(date).toLocaleTimeString("US", {
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    })
    : "";
};

export const getServicesList = (
  states: IStateDesignation[]
): { id: string; name: string }[] =>
  states.reduce((acc, state) => {
    acc.push(
      ...state.services.map((el) => ({
        name: el.name,
        id: el.id,
      }))
    );
    return acc;
  }, [] as { id: string; name: string }[]);

export const maskEmail = (email: string): string => {
  const emailRegex = /(?<=.{3}).(?=.*@)/g;
  const maskedEmail = email.replace(emailRegex, "*");
  return maskedEmail;
};

export const environment = process.env.REACT_APP_ENVIRONMENT?.toLowerCase();
export const isLocal = environment === 'local';
export const isDevelopment = environment === 'development';
export const isTest = environment === 'test';
export const isProduction = environment === 'production';
