import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api/index";
import { IError } from "../types/common";
import { IAppointmentItem, IAppointmentHistory, IDeleteAppointmentItem, IDispositionItem, IParams } from "./types";
import { showErrorNotify, showSuccessNotify } from "../../utils/notify";

export const getAppointment = createAsyncThunk<
  IAppointmentItem[],
  { params: IParams },
  { rejectValue: IError }
>("appointmentSlice/getAppointment", async (payload, { rejectWithValue }) => {
  try {
    return await API.getAppointment(payload.params);
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const setAppointmentModal = createAsyncThunk<
  boolean,
  { isOpenModal: boolean },
  { rejectValue: IError }
>("appointmentSlice/setAppointmentModal", (payload, { rejectWithValue }) => {
  try {
    return payload.isOpenModal;
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const createNewAppointment = createAsyncThunk<
  IAppointmentItem,
  { data: IAppointmentItem },
  { rejectValue: IError }
>(
  "appointmentSlice/createNewAppointment",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await API.createAppointment(payload.data);
      showSuccessNotify("Appointment was created");
      return data;
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      showErrorNotify(error);
      return rejectWithValue({ ...data });
    }
  }
);

export const updateAppointmentHistory = createAsyncThunk<
  IAppointmentHistory,
  { data: IAppointmentHistory },
  { rejectValue: IError }
>(
  "appointmentSlice/updateAppointmentHistory",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await API.updateAppointmentHistory(payload.data);
      return data;
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      showErrorNotify(error);
      return rejectWithValue({ ...data });
    }
  }
);

export const getAppointmentHistory = createAsyncThunk<
  IAppointmentHistory,
  { data: string },
  { rejectValue: IError }
>(
  "appointmentSlice/updateAppointmentHistory",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await API.getAppointmentHistory(payload.data);
      return data;
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      showErrorNotify(error);
      return rejectWithValue({ ...data });
    }
  }
);

export const updateAppointment = createAsyncThunk<
  IAppointmentItem,
  { data: IAppointmentItem },
  { rejectValue: IError }
>(
  "appointmentSlice/updateAppointment",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await API.updateAppointment(payload.data);
      showSuccessNotify("Appointment was updated");
      return data;
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      showErrorNotify(error);
      return rejectWithValue({ ...data });
    }
  }
);

export const deleteAppointment = createAsyncThunk<
  string,
  IDeleteAppointmentItem,
  { rejectValue: IError }
>(
  "appointmentSlice/deleteAppointment",
  async (payload, { rejectWithValue }) => {
    try {
      await API.deleteAppointment(payload);
      showSuccessNotify("Appointment was deleted");
      return payload.ids[0];
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      showErrorNotify(error);
      return rejectWithValue({ ...data });
    }
  }
);

export const updateDispositionAppointment = createAsyncThunk<
  IDispositionItem,
  { data: IDispositionItem },
  { rejectValue: IError }
>(
  "appointmentSlice/updateDispositionAppointment",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await API.updateDispositionAppointment(payload.data);
      return data;
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);