import { createSlice } from "@reduxjs/toolkit";
import {
  getUsers,
  getUsersSelectors,
  selectedAllUsers,
  uploadUserCsv,
} from "./thunks";
import { IState } from "./types";

const initialState: IState = {
  users: [],
  isSelected: false,
  error: null,
  status: "idle",
  userSelectors: [],
  usersNumber: 0,
  params: {},
};

export const usersSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {
    resetParams: (state) => {
      state.params = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.error = null;
      state.users = payload.data;
      state.usersNumber = payload.usersNumber;
      state.params = payload.params;
      state.isSorted = payload?.isSorted;
      state.status = "idle";
    });
    builder.addCase(uploadUserCsv.fulfilled, (state, { payload }) => {
      state.error = null;
      state.users = [...state.users, ...payload];
    });
    builder.addCase(selectedAllUsers.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isSelected = payload.selected;
      state.users = state.users.map((user) => ({
        ...user,
        selected: payload.selected,
      }));
    });
    builder.addCase(getUsers.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.status = "idle";
    });
    builder.addCase(uploadUserCsv.rejected, (state, { payload }) => {
      if (payload) {
        const errorList = Object.entries(payload.errors);
        state.error = errorList.length ? errorList : payload.title;
      }
      state.status = "idle";
    });
    builder.addCase(getUsersSelectors.fulfilled, (state, { payload }) => {
      state.userSelectors = payload;
    });
  },
});

export default usersSlice.reducer;
