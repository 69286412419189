import { useEffect, FC, useState } from "react";
import Routes from "./routes";

import { useAppDispatch } from "./store";
import { getAuthUser } from "./store/auth/thunks";
import { ThemeProvider } from "./providers";
import * as signalR from "@microsoft/signalr";
import "./App.css";
import { getAppointment } from "./store/appointment/thunk";

const App: FC = () => {
  const dispatch = useAppDispatch();
  const [connection, setConnection] = useState<null | signalR.HubConnection>(
    null
  );

  useEffect(() => {
    dispatch(getAuthUser());
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_API_URL + "/hubs")
      .configureLogging(signalR.LogLevel.Information)
      .build();
    setConnection(connection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("SendUINotification", (message) => {
            console.log(message);
            fetchLatest();
          });
        })
        .catch((error) => console.log(error));
    }
  }, [connection]);

  const fetchLatest = async () => {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month + 2, 0);
    const params = {
      startDate,
      endDate,
    };
    await dispatch(getAppointment({ params }));
  };

  return (
    <ThemeProvider>
      <Routes />
    </ThemeProvider>
  );
};

export default App;
