import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api/index";
import { IError, IParams } from "../types/common";
import { IServiceItems, IStateItems } from "../types/state";
import { IStateSelectorsItems } from "./types";
import { showErrorNotify } from "../../utils/notify";

export const getStatesList = createAsyncThunk<
  IStateItems[],
  { params?: IParams } | undefined,
  { rejectValue: IError }
>("statesSlice/getStatesList", async (payload, { rejectWithValue }) => {
  try {
    return await API.getStates(payload?.params);
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const getState = createAsyncThunk<
  IStateItems,
  { id: string },
  { rejectValue: IError }
>("stateSlice/getState", async ({ id }, { rejectWithValue }) => {
  try {
    return await API.getState(id);
  } catch (error) {
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});
export const createState = createAsyncThunk<
  IStateItems,
  IStateItems,
  { rejectValue: IError }
>(
  "statesSlice/createState",
  async (
    { name, description, medicalCredentials, status },
    { rejectWithValue }
  ) => {
    try {
      return await API.createState({
        name,
        description,
        medicalCredentials,
        status,
      });
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);

export const deleteState = createAsyncThunk<
  { id: string },
  { id: string },
  { rejectValue: IError }
>("statesSlice/deleteState", async ({ id }, { rejectWithValue }) => {
  try {
    await API.deleteState(id);
    return { id };
  } catch (error) {
    showErrorNotify(error);
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const deleteStatesService = createAsyncThunk<
  { id: string },
  { id: string },
  { rejectValue: IError }
>("stateSlice/deleteStatesService", async ({ id }, { rejectWithValue }) => {
  try {
    await API.deleteService(id);
    return { id };
  } catch (error) {
    showErrorNotify(error);
    const { data } = error as {
      data: IError;
    };
    return rejectWithValue({ ...data });
  }
});

export const createStatesService = createAsyncThunk<
  IServiceItems,
  { requestData: IServiceItems },
  { rejectValue: IError }
>(
  "stateSlice/createStatesService",
  async ({ requestData }, { rejectWithValue }) => {
    try {
      return await API.createService(requestData);
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);

export const updateStatesService = createAsyncThunk<
  IServiceItems,
  { requestData: IServiceItems },
  { rejectValue: IError }
>(
  "stateSlice/updateStatesService",
  async ({ requestData }, { rejectWithValue }) => {
    try {
      return await API.updateService(requestData);
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);

export const getStatesSelectors = createAsyncThunk<
  IStateSelectorsItems[],
  { params?: IParams } | undefined,
  { rejectValue: IError }
>(
  "statesSelectorsSlice/getStatesSelectors",
  async (payload, { rejectWithValue }) => {
    try {
      return await API.getStatesSelectors(payload?.params);
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);
