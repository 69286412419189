import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api/index";
import { IError } from "../types/common";
import { INotificationItem } from "./types";
import { IParams } from "../../api/services/notificationRequest/types";

export const getNotifications = createAsyncThunk<
  {
    data: INotificationItem[];
    totalCount: number;
    notificationStartList: number;
  },
  { params?: IParams } | void,
  { rejectValue: IError }
>(
  "notificationsSlice/getNotifications",
  async (payload, { rejectWithValue }) => {
    try {
      const { headers, data } = await API.getNotifications(payload?.params);
      const totalCount = +headers["x-total-count"];
      return {
        data,
        totalCount,
        notificationStartList: payload?.params?.start || 0,
      };
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);

export const getNotificationsCount = createAsyncThunk<
  number,
  void,
  { rejectValue: IError }
>(
  "notificationsSlice/getNotificationsCount",
  async (_, { rejectWithValue }) => {
    try {
      return await API.getNotificationsCount();
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);

export const readNotification = createAsyncThunk<
  string,
  { id: string },
  { rejectValue: IError }
>(
  "notificationsSlice/readNotification",
  async (payload, { rejectWithValue }) => {
    try {
      await API.readNotification(payload.id);
      return payload.id;
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);

export const deleteNotification = createAsyncThunk<
  string,
  { id: string },
  { rejectValue: IError }
>(
  "notificationsSlice/deleteNotification",
  async (payload, { rejectWithValue }) => {
    try {
      await API.deleteNotification(payload.id);
      return payload.id;
    } catch (error) {
      const { data } = error as {
        data: IError;
      };
      return rejectWithValue({ ...data });
    }
  }
);
