import { apiPrivateAxios } from "../../axios";
import { IImageFile } from "./types";
import { IReport } from "../../../components/layouts/ReportsLayout/form/types";

export const fileRequest = {
  uploadFile: (data: FormData): Promise<string> =>
    apiPrivateAxios.post("/file", data),
  uploadImageFile: (data: FormData): Promise<IImageFile> =>
    apiPrivateAxios.post("/file/image", data),
  getFileUrl: (photoFileKey: string): Promise<string> =>
    apiPrivateAxios.get(`/file/${photoFileKey}`),
  getGeneralReportFile: (data: IReport): Promise<Blob> =>
    apiPrivateAxios.get("/reports/general-report", {
      params: data,
      responseType: "blob",
    }),
  getPatientReportFile: (data: IReport): Promise<Blob> =>
    apiPrivateAxios.get("/reports/patient-report", {
      params: data,
      responseType: "blob",
    }),
};

export default { fileRequest };
