import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";

export default makeStyles((theme: Theme) => ({
  signUpWrapper: {
    flexDirection: "row",
  },
  signUpFormBlock: {
    margin: theme.spacing(0, 10, 0, 11),
    display: "flex",
    flexDirection: "column",
  },
  signUpLogo: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(10),
    maxWidth: "50%",
  },
  signUpForm: {
    display: "flex",
    flexDirection: "column",
  },
  signUpBg: {
    position: "absolute",
    right: 0,
    width: 755,
    height: "100vh",
  },
  signUpHead: {
    margin: theme.spacing(5, 0),
    fontWeight: 700,
    fontSize: 26,
  },
  signUpLinkWrap: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formFields: {
    marginBottom: theme.spacing(2),
    width: 455,
  },
  resetPasswordLink: {
    textDecoration: "none",
    color: "#2076F2",
    fontWeight: 700,
  },
  sendOTP: {
    height: 50,
    marginTop: 50,
    width: 250,
    fontSize: 20,
  },
  otpGroup: {
    display: "flex",
    width: "100%",
    maxWidth: 400,
    columnGap: 20,
  },
  otpInput: {
    width: " 100%",
    height: 60,
    border: "none",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    textAlign: "center",
    fontSize: 25,
    fontWeight: "bold",
    lineHeight: 1,
    "&.Mui-focus": {
      outline: "none",
      border:"none",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    }
  },
  container: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
  },
  verifyButton: {
    marginTop: theme.spacing(5),
    height: 50,
    justifyContent: "center",
    fontSize: 20,
  },
  backLink: {
    textDecoration: "none",
    marginTop: theme.spacing(5),
    display: "flex",
    alignItems: "center",
  },
  linkText: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.black,
  },
  resendOTPWrap: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  resendOTPText: {
    textDecoration: "none",
    color: "#2076F2",
    fontWeight: 700,
    backgroundColor: "transparent",
  },
  rememberMe: {
    marginTop: theme.spacing(2),
  },
}));
