import React, { ChangeEvent, FC } from "react";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Link, useNavigate } from "react-router-dom";
import { Refresh } from "@mui/icons-material";
import OtpInput from "./OtpInput";
import useStyles from "./styles";
import Button from "../../../components/common/Button";
import { useAppDispatch } from "../../../store";
import { generateOtp, verifyOTP } from "../../../store/auth/thunks";
import { getLocalTokenData } from "../../../api/services/localData/tokenActions";
import { getUserEmail } from "../../../utils/token";
import { maskEmail } from '../../../utils/helpers';

const TwoFactorGuard: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const facilityEmail = getUserEmail();
  const [otp, setOtp] = React.useState("");
  const [showOTPInput, setShowOTPInput] = React.useState(false);
  const [checkedValue, setValue] = React.useState("email");
  const [isLoading, setisLoading] = React.useState(false);
  const [isRememberedMe, setIsRememberedMe] = React.useState(false);
  const [isVerificationLoading, setisVerificationLoading] = React.useState(false);

  const options = [
    {
      value: "email",
      label: maskEmail(facilityEmail),
    },
  ];

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onChange = (value: string) => {
    setValue(value);
  };

  const handleSendOTP = (): void => {
    setisLoading(true);
    dispatch(
      generateOtp({
        method: checkedValue,
        authorityOrigin: "WEB",
        accessToken: getLocalTokenData()?.accessToken,
      })
    );
    setTimeout(() => {
      setisLoading(false);
      if (getLocalTokenData()?.accessToken) {
        setShowOTPInput(true);
      }
    }, 2000);
  };

  const handleVerifyOTP = (): void => {
    setisVerificationLoading(true);
    dispatch(
      verifyOTP({
        otp,
        accessToken: getLocalTokenData()?.accessToken,
        authorityOrigin: "WEB",
        isRememberMe: isRememberedMe,
        navigate,
      })
    );
    setTimeout(() => {
      setisVerificationLoading(false);
    }, 2000);
  };

  const handleReSendOTP = (): void => {
    dispatch(
      generateOtp({
        method: checkedValue,
        authorityOrigin: "WEB",
        accessToken: getLocalTokenData()?.accessToken,
      })
    );
    setOtp("");
  };

  const handleRememberMe = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsRememberedMe(event.target.checked);
  };

  const onChangeOtp = (value: string): void => setOtp(value);
  return (
    <div className={classes.signUpWrapper}>
      <div className={classes.signUpFormBlock}>
        <Link to="/auth/login" className={classes.backLink}>
          <img src="/images/login/leftArrow.svg" alt="left-arrow" />
          <Typography variant="body1" className={classes.linkText}>
            Go To Sign In
          </Typography>
        </Link>
        <img
          src="/images/login/logo.svg"
          alt="logo"
          className={classes.signUpLogo}
        />
        { process.env.REACT_APP_ENVIRONMENT !== "production" && 
          <Typography variant="h6" color="gray" align="left">{process.env.REACT_APP_ENVIRONMENT}</Typography>
        }
        <Typography variant="h2" className={classes.signUpHead}>
          Two Factor Authentication
        </Typography>
        <RadioGroup>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              control={
                <Radio
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<TaskAltIcon />}
                  onChange={() => onChange(option.value)}
                />
              }
              checked={checkedValue === option.value}
              label={option.label}
              labelPlacement="end"
            />
          ))}
        </RadioGroup>
        <Button
          onClick={() => handleSendOTP()}
          className={classes.sendOTP}
          isLoading={isLoading}
        >
          Send Code
        </Button>
        {showOTPInput && (
          <>
            <div className={classes.resendOTPWrap}>
              <Button
                variant="text"
                className={classes.resendOTPText}
                onClick={() => handleReSendOTP()}
                startIcon={<Refresh />}
                disableElevation
              >
                Didn’t receive OTP?
              </Button>
            </div>
            <div className={classes.container}>
              <OtpInput value={otp} valueLength={6} onChange={onChangeOtp} />
              <Button
                className={classes.verifyButton}
                onClick={() => handleVerifyOTP()}
                isLoading={isVerificationLoading}
              >
                Verify Code
              </Button>
              <FormControlLabel
                label="Trust this device for 3 days"
                className={classes.rememberMe}
                control={
                  <Checkbox
                    checked={isRememberedMe}
                    onChange={handleRememberMe}
                    color="primary"
                  />
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TwoFactorGuard;
