import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import patientsSlice from "./patients/patientsSlice";
import usersSlice from "./users/usersSlice";
import statesSlice from "./states/statesSlice";
import stateSlice from "./states/stateSlice";
import servicesSlice from "./services/servicesSlice";
import appointmentSlice from "./appointment/appointmentSlice";
import officeSlice from "./office/officeSlice";
import stateDesignationSlice from "./stateDesignation/stateDesignationSlice";
import statesSelectorsSlice from "./states/statesSelectorsSlice";
import reportsSlice from "./reports/reportsSlice";
import patientReportsSlice from "./reports/patientReportsSlice";
import dashboardSlice from "./dashboard/dashboardSlice";
import notificationsSlice from "./notifications/notificationsSlice";
import buttonSlice from "./activeButton/buttonSlice";
import adminFacilitySlice from "./adminFacility/adminFacilitySlice";

const rootReducer = combineReducers({
  auth: authSlice,
  users: usersSlice,
  patients: patientsSlice,
  statesList: statesSlice,
  state: stateSlice,
  stateSelectors: statesSelectorsSlice,
  service: servicesSlice,
  appointment: appointmentSlice,
  office: officeSlice,
  stateDesignation: stateDesignationSlice,
  reports: reportsSlice,
  patientReports: patientReportsSlice,
  dashboard: dashboardSlice,
  notifications: notificationsSlice,
  activeButton: buttonSlice,
  adminFacility: adminFacilitySlice,
});

export default rootReducer;
