import { createSlice } from "@reduxjs/toolkit";
import { IServiceState } from "./types";
import { getService, getServiceSelectors } from "./thunks";

const initialState: IServiceState = {
  service: {
    cpt: "",
    deliveryTypes: [],
    documents: "",
    dueDate: "",
    id: "",
    name: "",
    stateDesignationId: "",
    status: "",
    typeName: "",
  },
  error: null,
  responseStatus: "idle",
  serviceSelectors: [],
};

export const servicesSlice = createSlice<IServiceState, {}>({
  name: "servicesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getService.pending, (state) => {
      state.responseStatus = "loading";
      state.error = null;
    });
    builder.addCase(getService.fulfilled, (state, { payload }) => {
      state.error = null;
      state.service = payload;
    });
    builder.addCase(getService.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.responseStatus = "idle";
    });
    builder.addCase(getServiceSelectors.fulfilled, (state, { payload }) => {
      state.serviceSelectors = payload;
    });
  },
});

export default servicesSlice.reducer;
