import { IStateItems } from "../../../store/types/state";
import { apiPrivateAxios } from "../../axios";
import { IParams } from "../../../store/types/common";
import { IStateSelectorsItems } from "../../../store/states/types";

export const statesRequest = {
  getStates: (params?: IParams): Promise<IStateItems[]> =>
    apiPrivateAxios.get("/state-designation", {
      params,
    }),
  getState: (id: string): Promise<IStateItems> =>
    apiPrivateAxios.get(`/state-designation/${id}`),
  createState: ({
    name,
    description,
    medicalCredentials,
    status,
  }: IStateItems): Promise<IStateItems> =>
    apiPrivateAxios.post("/state-designation", {
      name,
      description,
      medicalCredentials,
      status,
    }),
  deleteState: (id: string): Promise<void> =>
    apiPrivateAxios.delete(`/state-designation`, { data: { id } }),
  getStatesSelectors: (params?: IParams): Promise<IStateSelectorsItems[]> =>
    apiPrivateAxios.get("/state-designation/selector", {
      params,
    }),
};

export default { statesRequest };
