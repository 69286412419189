import qs from "qs";
import {
  IAppointmentItem,
  IClinicianAppointment,
  IDeleteAppointmentItem,
  IParams,
  IDispositionItem,
  IAppointmentHistory,
} from "../../../store/appointment/types";
import { apiPrivateAxios } from "../../axios";

export const appointmentRequest = {
  getAppointment: (params: IParams): Promise<IAppointmentItem[]> =>
    apiPrivateAxios.get("/appointment", {
      params: { ...params, size: 999 },
      paramsSerializer: (paramsData) =>
        // TODO: replace to the custom method (not use qs)
        qs.stringify(paramsData, { arrayFormat: "repeat" }),
    }),
  getAppointmentById: (id: string): Promise<IAppointmentItem[]> =>
    apiPrivateAxios.get(`/appointment/${id}`),
  getNotScheduledAppointmentById: (id: string): Promise<IAppointmentItem[]> =>
    apiPrivateAxios.get(`/appointment/${id}/not-scheduled`),

  createAppointment: (data: IAppointmentItem): Promise<IAppointmentItem> =>
    apiPrivateAxios.post("/appointment", data),
  updateAppointment: (data: IAppointmentItem): Promise<IAppointmentItem> =>
    apiPrivateAxios.put(`/appointment/${data.id}`, data),
  deleteAppointment: (
    data: IDeleteAppointmentItem
  ): Promise<IAppointmentItem> =>
    apiPrivateAxios.put(`/appointment/status`, data),
  getClinicianAppointment: (): Promise<IClinicianAppointment[]> =>
    apiPrivateAxios.get("/appointment/clinician-appointments"),
  updateDispositionAppointment: (data:IDispositionItem): Promise<IDispositionItem> =>
    apiPrivateAxios.put(`/appointment/${data.id}/disposition`, data),
  getAppointmentHistory: (id: string): Promise<IAppointmentHistory> =>
    apiPrivateAxios.get(`/appointment/${id}/history`),
  updateAppointmentHistory: (data: IAppointmentHistory): Promise<IAppointmentHistory> =>
    apiPrivateAxios.put(`/appointment/${data.id}/history`, data),
};

export default { appointmentRequest };
