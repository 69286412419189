import authRequest from "./services/authRequest";
import patientsRequests from "./services/patientsRequests";
import usersRequests from "./services/usersRequests";
import servicesRequest from "./services/servicesRequest";
import statesRequest from "./services/statesRequest";
import appointmentRequest from "./services/appointmentRequest";
import officeRequest from "./services/officeRequest";
import stateDesignationRequest from "./services/stateDesignationRequest";
import geocodingRequest from "./services/geocodingRequest";
import fileRequest from "./services/fileRequest";
import reportsRequest from "./services/reportsRequest";
import adtNotificationRequest from "./services/adtNotificationRequest";
import dashboardRequests from "./services/dashboardRequests/dashboardRequests";
import assignmentsOptimizationRequest from "./services/assignmentsOptimizationRequest";
import notificationRequest from "./services/notificationRequest";
import adminFacilityRequest from "./services/adminFacilityRequests";

export default {
  ...authRequest,
  ...usersRequests,
  ...patientsRequests,
  ...statesRequest,
  ...servicesRequest,
  ...appointmentRequest,
  ...officeRequest,
  ...stateDesignationRequest,
  ...geocodingRequest,
  ...fileRequest,
  ...reportsRequest,
  ...adtNotificationRequest,
  ...dashboardRequests,
  ...assignmentsOptimizationRequest,
  ...notificationRequest,
  ...adminFacilityRequest,
};
